import React from "react"
import { JesLayout } from "../../components/jes-layout"
import { JesRow, HalfCol } from "../../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { SubHeader } from "../../components/subheader"
import {
  PrimaryHeading,
  StandardParagraph,
  LearnMoreLink,
  BLOCKS_SPACING,
} from "../../components/blocks"
import { useMediaQuerySafe } from "../../components/use-mq-safe"
import { Trans } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"

interface DescriptionBlockProps {
  title?: string
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
}

const EuroArtPage = ({ data }) => {
  const [isMobile, setisMobile] = React.useState(false)
  const colSize = useMediaQuerySafe()

  React.useEffect(() => {
    setisMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  const { t } = useI18next()
  return (
    <JesLayout
      title={`EuroArt | ${t("Projekte")}`}
      beforeContent={<SubHeader />}
    >
      <JesRow align="top" noPadding noGutter>
        <HalfCol>
          <div
            style={{
              width: "100%",
              maxWidth: "400px",
              marginBottom: isMobile ? BLOCKS_SPACING : 0,
            }}
          >
            <Img fluid={data.euroartLogo.childImageSharp.fluid} />
          </div>
          <br />
        </HalfCol>
        <HalfCol>
          <PrimaryHeading>
            <Trans i18nKey="euroart.heading">
              Mitgliedschaft der Künstlerkolonie Holzhausen im Verbund
              „euroArt“.
            </Trans>
          </PrimaryHeading>
          <div>
            <StandardParagraph>
              <Trans i18nKey="euroart.p1">
                Auf Initiative der JES Kulturstiftung und des Vereins „Unser
                Dorf“ Holzhausen hat der Gemeinderat von Utting am Ammersee im
                Dezember 2020 einstimmig beschlossen, einen Antrag zur Aufnahme
                der Künstlerkolonie Holzhausen am Ammersee in den Verbund
                Europäischer Künstlerkolonien „euroArt“ zu stellen. Die Aufnahme
                in „euroArt“ erfolgte im März 2021.
              </Trans>
            </StandardParagraph>
            <StandardParagraph>
              <Trans i18nKey="euroart.p2">
                Das euroArt Netzwerk umfasst zur Zeit über 80 Institutionen,
                gemeinnützige Vereine und Gemeinden in 14 europäischen Ländern
              </Trans>
            </StandardParagraph>
            <StandardParagraph>
              <Trans i18nKey="euroart.p3">
                Dies unterstreicht die überregionale Bedeutung der
                Künsterkolonie Holzhausen am Ammersee. Die nächst gelegenen
                Partner sind die Künstlerkolonien Murnau, Dachau und Prien.
              </Trans>
            </StandardParagraph>
            <LearnMoreLink
              to={"https://euroart.eu"}
              target="_blank"
              style={{ display: "block", textAlign: "right" }}
              text={t("ZUR WEBSITE")}
            />
          </div>
        </HalfCol>
      </JesRow>
    </JesLayout>
  )
}

export default EuroArtPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    euroartLogo: file(relativePath: { eq: "euroart_logo_captioned.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jochenUndErika: file(relativePath: { eq: "jochenunderikaseifert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogoTop: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 375) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukLogo: file(relativePath: { eq: "kuk_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    holzausen: file(relativePath: { eq: "holzhausen_postkarte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukStartPage: file(relativePath: { eq: "kuk_design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukShowcaseErler: file(relativePath: { eq: "kukart-showcase-erler.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukArtists: file(relativePath: { eq: "kuk_artists_page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukKunstUndKultur: file(relativePath: { eq: "kuk-kunstundkultur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jugend: file(relativePath: { eq: "jugend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
